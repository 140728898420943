import * as React from 'react'
import { graphql } from 'gatsby'
import logoWithoutText from '../images/logo-without-text.png'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { Helmet } from 'react-helmet'

// COMPONENTS
import Header from '../components/header'
import Footer from '../components/footer'

//STYLES
import '../styles/support.scss'
import { useState } from 'react'

const Support = ({ data }) => {
  const [openQuestion, setOpenQuestion] = useState(null)

  const handleClick = question => {
    setOpenQuestion(question === openQuestion ? null : question)
  }

  const md = data.allMarkdownRemark.edges[0].node.frontmatter

  return (
    <div id="support">
      <Helmet>
        <title>Support</title>
        <meta
          name="description"
          content="På denna sida kan du hitta allmänna frågor som kan lösa dina problem och vår egna checklista av vad du behöver för att kunna använda iHug på dina enheter."
        ></meta>
      </Helmet>
      <div className="background-image" alt="background image">
        <Header />
        <h1 className="header-title">{md.title}</h1>
      </div>
      <div className="checklist-section">
        <div>
          <h2 className="iHug-h2">{md.checklist}</h2>
          <div className="checklist">
            <img
              className="checklist-logo"
              src={logoWithoutText}
              alt="logo"
            ></img>
            <div className="inside-checklist">
              {md.checklistArray.map((item, i) => (
                <div key={i} className="checklist-item">
                  <AiOutlineCheckCircle className="check-icon" />
                  <span className="checklist-item-text">{item.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="info-text">
          <p className="text-p">{md.infoText}</p>
        </div>
      </div>
      {/* STANDARD QUESTIONS */}
      <div className="questions-section">
        <h2 className="standard-questions">{md.standardQuestions}</h2>
        <h1 onClick={() => handleClick()}> </h1>
        {md.standardQuestionsArray.map((item, i) => (
          <div
            key={i}
            className="question"
            onClick={() => handleClick(item.question)}
          >
            <p className="question-text">
              {item.question}{' '}
              {openQuestion === item.question ? <span>-</span> : <span>+</span>}{' '}
            </p>
            {openQuestion === item.question && (
              <p className="question-answer">{item.answer}</p>
            )}
          </div>
        ))}
        {/* TROUBLESHOOT QUESTIONS */}
        <h2 className="standard-questions">{md.troubleshootQuestions}</h2>
        {md.troubleshootQuestionsArray.map((item, i) => (
          <div
            key={i}
            className="question"
            onClick={() => handleClick(item.question)}
          >
            <p className="question-text">
              {item.question}{' '}
              {openQuestion === item.question ? <span>-</span> : <span>+</span>}
            </p>
            {openQuestion === item.question && (
              <p className="question-answer">{item.answer}</p>
            )}
          </div>
        ))}
        {/* TUTORIAL QUESTIONS */}
        <h2 className="standard-questions">{md.tutorialQuestions}</h2>
        {md.tutorialQuestionsArray.map((item, i) => (
          <div
            key={i}
            className="question"
            onClick={() => handleClick(item.question)}
          >
            <p className="question-text">
              {item.question}{' '}
              {openQuestion === item.question ? <span>-</span> : <span>+</span>}
            </p>
            {openQuestion === item.question && (
              <p className="question-answer">{item.answer}</p>
            )}
          </div>
        ))}
      </div>
      <Footer />
    </div>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(filter: { frontmatter: { key: { eq: "support" } } }) {
      edges {
        node {
          frontmatter {
            title
            checklist
            infoText
            checklistArray {
              name
            }
            standardQuestionsArray {
              question
              answer
            }
            standardQuestions
            troubleshootQuestions
            troubleshootQuestionsArray {
              answer
              question
            }
            tutorialQuestionsArray {
              answer
              question
            }
            tutorialQuestions
          }
        }
      }
    }
  }
`

export default Support
